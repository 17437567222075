import React from 'react';
import "./backdrop.css";


const backdrop = props => (

    <div className="backdrop"  onClick={props.click}>
        <div className="moveX">
         <div className="toggle_line_x1"/>
         <div className="toggle_line_x2"/>
         </div>
    </div>


);
export default backdrop;