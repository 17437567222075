import React, { Component } from "react";
import Header from "../Components/header/Header";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import "./contact.css"
import LandingPhoto from "../images/allison.JPG"
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Chalk from "../images/chalkboardtest.jpg"
import SummaryImage from "../images/summaryPhoto.JPG"
import Insta from "../images/insta.svg"



class Contact extends Component {

    state = {
      sideDrawerOpen: false
    };
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen}
      });
    };
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false})
    };  
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler}/>
      }
    return (
      <div className="contact-wrap">
      <Header drawerClickHandler={this.drawerToggleClickHandler} />
      <div>
         {sideDrawer}
         {backdrop}  
         </div> 
         <BackgroundImage className="App"
         id="contact-app"
       src={LandingPhoto}
       style={{  
       backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
  
        }}>
    <Tabs className="tabwrap">
    <TabList className="tablist">
      <Tab><p>Info</p></Tab>
      <Tab><p>Contact Me</p></Tab>
    </TabList>
    <TabPanel className="panel">
    <div className="overall-section">
        <div className="summary-wrap">
          <div className="summary-top-flex">
          <h4 className="summary-title">What I Do</h4>
          <img src={SummaryImage} alt="alli jo" className="summary-image" />
          </div>
        <p className="contact-text-one">Photography is my passion. My intent is to capture your moments in clarity. I offer photography services for personal portrait's, couples, small event's, and weddings. Please feel free to contact me with any question's about services offered.<br></br><br></br><span className="contact-alt-text">Pricing listed below</span></p>
        
        </div>
        <div className="pricing-wrap"
    
       style={{  
        backgroundImage: `url(${Chalk})`,
       backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
        }}>
        <h3 className="pricing-title">Pricing</h3>
       <div className="price-list-wrap">
         <ul className="price-list">
           <li className="price-list-item">
            <h4 className="price-list-item-title">Portraits</h4><p className="price-text">$100<span className="price-text-span">Hourly</span></p>
           </li>
           <li className="price-list-item">
           <h4 className="price-list-item-title">Family</h4><p className="price-text">$175<span className="price-text-span">Hourly</span></p>
           </li>
           <li className="price-list-item">
           <h4 className="price-list-item-title">Couples</h4><p className="price-text">$120<span className="price-text-span">Hourly</span></p>
           </li>
           <li className="price-list-item">
           <h4 className="price-list-item-title">boudoir</h4><p className="price-text">$200<span className="price-text-span">Hourly</span></p>
           </li>
           <li className="price-list-item">
           <h4 className="price-list-item-title">Events/Weddings</h4>
           <h3 className="price-list-wedding-title">gold</h3><p className="price-text">$1,800</p>
           <h3 className="price-list-wedding-title">silver</h3><p className="price-text">$1,600</p>
           <h3 className="price-list-wedding-title">standard</h3><p className="price-text">$1,400</p>
           </li>
         </ul>
       </div>
        </div>
        </div>
    </TabPanel>
    <TabPanel className="panel">
    <form className="contact-form">
      <p className="contact-email">Contact me at: alli.jo.photography@gmail.com</p>
      <a href="https://www.instagram.com/alli.jo.photography/" target="blank__"><img src={Insta} alt="instagram" className="contact-insta-link" /></a>
      <p className="contact-email">^or follow my instagram with the link above^</p>
     
      </form>
      </TabPanel>
    </Tabs>
    </BackgroundImage>
    </div>
    );
      }}
  
  export default Contact;