import React from "react";
import "./header.css";



function HeaderLanding () {
    return (
  <div className="header">
     <h1 className="header-title">Alli Jo Photography</h1>
  </div>
    );
}

export default HeaderLanding;