import React, { Component } from "react";
import AlbumsHeader from "../Components/header/AlbumsHeader";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import "./Portraits.css"
import PortraitA from "../albums/portraits/family/album-family1-1.jpg"
import PortraitB from "../albums/portraits/family/album-family1-2.jpg"
import PortraitC from "../albums/portraits/family/album-family-1-3.jpg"
import PortraitD from "../albums/portraits/family/album-family1-4.jpg"
import PortraitE from "../albums/portraits/family/album-family1-5.jpg"
import PortraitF from "../albums/portraits/family/album-family1-6.jpg"
import PortraitG from "../albums/portraits/family/album-family1-7.jpg"
import PortraitH from "../albums/portraits/family/album-family1-8.jpg"
import PortraitI from "../albums/portraits/family/album-family1-9.jpg"
import PortraitJ from "../albums/portraits/family/album-family1-10.jpg"
import PortraitK from "../albums/portraits/family/album-family1-11.jpg"
import PortraitL from "../albums/portraits/family/album-family1-12.jpg"
import PortraitM from "../albums/portraits/family/album-family1-13.jpg"
import PortraitN from "../albums/portraits/family/album-family1-14.jpg"
import PortraitO from "../albums/portraits/family/album-family1-15.jpg"
import PortraitP from "../albums/portraits/family/album-family1-16.jpg"
import PortraitQ from "../albums/portraits/family/album-family1-17.jpg"
import PortraitR from "../albums/portraits/family/album-family1-18.jpg"
import PortraitS from "../albums/portraits/family/album-family1-19.jpg"
import PortraitT from "../albums/portraits/family/album-family1-20.jpg"
import PortraitU from "../albums/portraits/family/album-family1-21.jpg"
import PortraitV from "../albums/portraits/family/album-family1-23.jpg"
import PortraitW from "../albums/portraits/family/album-family1-24.jpg"
import PortraitX from "../albums/portraits/family/album-family1-25.jpg"
import PortraitY from "../albums/portraits/family/album-family1-26.jpg"
import PortraitZ from "../albums/portraits/family/album-family1-27.jpg"
import PortraitAB from "../albums/portraits/family/album-family1-28.jpg"
import PortraitAC from "../albums/portraits/family/album-family1-29.jpg"
import PortraitAD from "../albums/portraits/family/album-family1-30.jpg"
import PortraitAE from "../albums/portraits/family/album-family1-31.jpg"
import PortraitAF from "../albums/portraits/family/album-family1-32.jpg"
import PortraitAG from "../albums/portraits/family/album-family1-33.jpg"
import PortraitAH from "../albums/portraits/family/album-family1-34.jpg"
import PortraitAI from "../albums/portraits/family/album-family1-35.jpg"
import PortraitAJ from "../albums/portraits/family/album-family1-36.JPG"



class Family extends Component {

    state = {
      sideDrawerOpen: false
    };
    
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen}
      });
    };
    
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false})
    };  
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler}/>
      }
    return (
      <div>
      <AlbumsHeader drawerClickHandler={this.drawerToggleClickHandler} />
      <div>
         {sideDrawer}
         {backdrop}  
         </div> 
        <div className="portrait-wrap">
     
        <img src={PortraitB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitC} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitF} alt="alli jo" className="portrait-photo"/>
        
        <img src={PortraitH} alt="alli jo" className="portrait-photo"/>
      
        <img src={PortraitJ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitK} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitL} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitM} alt="alli jo" className="portrait-photo"/>
      
        <img src={PortraitO} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitQ} alt="alli jo" className="portrait-photo"/>  <img src={PortraitP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitR} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitS} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitV} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitW} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitX} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitY} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAC} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAF} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAH} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAI} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitA} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitI} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitN} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitT} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitU} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitZ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAJ} alt="alli jo" className="portrait-photo"/>
        </div>
       </div>
     
    );
  }
  }
  
  export default Family;


