import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
import Albums from "./pages/Albums";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Portraits from "./pages/Portraits";
import Events from "./pages/Events";
import Family from "./pages/Family";

const routing = (
  <Router>
    <div className="allWrap">
      <Route exact path="/" component={App} />
      <Route exact path="/Albums" component={Albums} />
      <Route exact path="/Contact" component={Contact} />
      <Route exact path="/About" component={About} />
      <Route exact path="/Albums/Portraits" component={Portraits} />
      <Route exact path="/Albums/Family" component={Family} />
      <Route exact path="/Albums/Events" component={Events} />
    </div>
  
 
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'))

