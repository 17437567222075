import React, { Component } from "react";
import AlbumsHeader from "../Components/header/AlbumsHeader";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import "./Portraits.css"
import PortraitA from "../albums/portraits/album-portrait-1-1.jpg"
import PortraitB from "../albums/portraits/album-portrait-1-2.jpg"
import PortraitC from "../albums/portraits/album-portrait-1-3.JPG"
import PortraitD from "../albums/portraits/album-portrait-1-4.JPG"
import PortraitE from "../albums/portraits/album-portrait-1-5.jpg"
import PortraitF from "../albums/portraits/album-portrait-1-6.jpg"
import PortraitG from "../albums/portraits/album-portrait-1-7.jpg"
import PortraitH from "../albums/portraits/album-portrait-1-8.jpg"
import PortraitI from "../albums/portraits/album-portrait-1-9.JPG"
import PortraitJ from "../albums/portraits/album-portrait-1-10.JPG"
import PortraitK from "../albums/portraits/album-portrait-1-11.JPG"
import PortraitL from "../albums/portraits/album-portrait-1-12.JPG"
import PortraitM from "../albums/portraits/album-portrait-1-13.JPG"
import PortraitN from "../albums/portraits/album-portrait-1-14.JPG"
import PortraitO from "../albums/portraits/album-portrait-1-15.JPG"
import PortraitP from "../albums/portraits/album-portrait-1-16.JPG"
import PortraitQ from "../albums/portraits/album-portrait-1-17.JPG"
import PortraitR from "../albums/portraits/album-portrait-1-18.JPG"
import PortraitS from "../albums/portraits/album-portrait-1-19.JPG"
import PortraitT from "../albums/portraits/album-portrait-1-20.JPG"
import PortraitU from "../albums/portraits/album-portrait-1-21.JPG"
import PortraitV from "../albums/portraits/album-portrait-1-23.JPG"
import PortraitW from "../albums/portraits/album-portrait-1-24.JPG"
import PortraitX from "../albums/portraits/album-portrait-1-25.JPG"
import PortraitY from "../albums/portraits/album-portrait-1-26.JPG"
import PortraitZ from "../albums/portraits/album-portrait-1-27.JPG"
import PortraitAB from "../albums/portraits/album-portrait-1-28.JPG"
import PortraitAC from "../albums/portraits/album-portrait-1-29.JPG"
import PortraitAD from "../albums/portraits/album-portrait-1-30.JPG"
import PortraitAE from "../albums/portraits/album-portrait-1-31.JPG"
import PortraitAF from "../albums/portraits/album-portrait-1-32.JPG"
import PortraitAG from "../albums/portraits/album-portrait-1-33.JPG"
import PortraitAH from "../albums/portraits/album-portrait-1-34.JPG"
import PortraitAI from "../albums/portraits/album-portrait-1-35.JPG"
import PortraitAJ from "../albums/portraits/album-portrait-1-36.JPG"
import PortraitAK from "../albums/portraits/album-portrait-1-37.JPG"
import PortraitAL from "../albums/portraits/album-portrait-1-38.JPG"
import PortraitAM from "../albums/portraits/album-portrait-1-39.JPG"
import PortraitAN from "../albums/portraits/album-portrait-1-40.JPG"
import PortraitAO from "../albums/portraits/album-portrait-1-41.JPG"
import PortraitAP from "../albums/portraits/album-portrait-1-42.JPG"
import PortraitAQ from "../albums/portraits/album-portrait-1-43.JPG"
import PortraitAR from "../albums/portraits/album-portrait-1-44.JPG"
import PortraitAS from "../albums/portraits/album-portrait-1-45.JPG"
import PortraitAT from "../albums/portraits/album-portrait-1-46.JPG"
import PortraitAU from "../albums/portraits/album-portrait-1-47.JPG"
import PortraitAV from "../albums/portraits/album-portrait-1-48.JPG"
import PortraitAW from "../albums/portraits/album-portrait-1-49.JPG"
import PortraitAX from "../albums/portraits/album-portrait-1-50.JPG"
import PortraitAY from "../albums/portraits/album-portrait-1-51.JPG"
import PortraitAZ from "../albums/portraits/album-portrait-1-52.JPG"
import PortraitABB from "../albums/portraits/album-portrait-1-53.JPG"
import PortraitABC from "../albums/portraits/album-portrait-1-54.JPG"
import PortraitABD from "../albums/portraits/album-portrait-1-55.JPG"
import PortraitABE from "../albums/portraits/album-portrait-1-56.JPG"
import PortraitABF from "../albums/portraits/album-portrait-1-57.JPG"
import PortraitABG from "../albums/portraits/album-portrait-1-58.JPG"
import PortraitABH from "../albums/portraits/album-portrait-1-59.JPG"
import PortraitABI from "../albums/portraits/album-portrait-1-60.JPG"
import PortraitABJ from "../albums/portraits/album-portrait-1-61.JPG"
import PortraitABK from "../albums/portraits/album-portrait-1-62.JPG"


class Portraits extends Component {

    state = {
      sideDrawerOpen: false
    };
    
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen}
      });
    };
    
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false})
    };  
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler}/>
      }
    return (
      <div>
      <AlbumsHeader drawerClickHandler={this.drawerToggleClickHandler} />
      <div>
         {sideDrawer}
         {backdrop}  
         </div> 
        <div className="portrait-wrap">
        <img src={PortraitAJ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitC} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitF} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitH} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitK} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitN} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitO} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitR} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitS} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitT} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitU} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitW} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitX} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitY} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAC} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAF} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAH} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAI} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAK} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAL} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAM} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAN} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAQ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAR} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAS} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAT} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAU} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAV} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAW} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAX} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAY} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAZ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABF} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABH} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABJ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABK} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitM} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitI} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitJ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitL} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABI} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitQ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitV} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitZ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitA} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitAO} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitABC} alt="alli jo" id="last-portrait" className="portrait-photo"/>
        </div>
       </div>
     
    );
  }
  }
  
  export default Portraits;


