import React, { Component } from "react";
import AlbumHeader from "../Components/header/AlbumsHeader";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import { HashLink } from "react-router-hash-link";
import { BackgroundImage } from 'react-image-and-background-image-fade';
import BackgroundOne from "../images/album-portrait-background.jpg";
import "./Albums.css";
import FamilyPhoto from "../images/family.JPG";
import EventsPhoto from "../images/events.jpg";


class Albums extends Component {

  state = {
    sideDrawerOpen: false
  };
  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    });
  };
  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  };
  render() {
    let sideDrawer;
    let backdrop;
    if (this.state.sideDrawerOpen) {
      sideDrawer = <SideDrawer />
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }
    return (
      <div>
        <AlbumHeader drawerClickHandler={this.drawerToggleClickHandler} />
        <div>
          {sideDrawer}
          {backdrop}
        </div>
        <div className="App" id="album-page">
          <div className="albums-link-wrap">
            <div className="first-link-wrap">
              <BackgroundImage className="albums-portraits-background"
                src={BackgroundOne}
                style={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}>
                <HashLink smooth to="Albums/Portraits" className="portraits_link">
                  <div className="portraits-link-text"><p className="link-text-p">Portraits</p></div>
                </HashLink>

              </BackgroundImage>
              <BackgroundImage className="albums-family-background"
                src={FamilyPhoto}
                style={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}>
                <HashLink smooth to="Albums/Family" className="portraits_link">
                  <div className="portraits-link-text"><p className="link-text-p">Family</p></div>
                </HashLink>

              </BackgroundImage>
              <BackgroundImage className="albums-family-background"
                src={EventsPhoto}
                style={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}>
                <HashLink smooth to="Albums/Events" className="portraits_link">
                  <div className="portraits-link-text"><p className="link-text-p">Events</p></div>
                </HashLink>

              </BackgroundImage>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

export default Albums;