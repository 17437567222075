import React from "react";
import './header.css';
import DrawerToggleButton from "../sideDrawer/DrawerToggleButton";


const AlbumHeader = props => (
  <div className="album-header">
       
      <DrawerToggleButton click={props.drawerClickHandler} />
  </div>
);

export default AlbumHeader;