
import React from "react";
import './header.css';
import DrawerToggleButton from "../sideDrawer/DrawerToggleButton";


const Header = props => (
  <div className="headingAlt">
       <h1 className="header-title">Alli Jo Photography</h1>
      <DrawerToggleButton click={props.drawerClickHandler} />
  </div>
);

export default Header;