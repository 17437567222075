import React, { Component } from "react";
import AlbumsHeader from "../Components/header/AlbumsHeader";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import "./Portraits.css"
import PortraitA from "../albums/portraits/events/album-events-1-1.JPG"
import PortraitB from "../albums/portraits/events/album-events-1-2.JPG"
import PortraitC from "../albums/portraits/events/album-events-1-3.JPG"
import PortraitD from "../albums/portraits/events/album-events-1-4.JPG"
import PortraitE from "../albums/portraits/events/album-events-1-5.JPG"
import PortraitF from "../albums/portraits/events/album-events-1-6.JPG"
import PortraitG from "../albums/portraits/events/album-events-1-7.JPG"
import PortraitH from "../albums/portraits/events/album-events-1-8.JPG"
import PortraitI from "../albums/portraits/events/album-events-1-9.JPG"
import PortraitJ from "../albums/portraits/events/album-events-1-10.JPG"
import PortraitK from "../albums/portraits/events/album-events-1-11.JPG"
import PortraitL from "../albums/portraits/events/album-events-1-12.JPG"
import PortraitM from "../albums/portraits/events/album-events-1-13.JPG"
import PortraitN from "../albums/portraits/events/album-events-1-14.JPG"
import PortraitO from "../albums/portraits/events/album-events-1-15.JPG"
import PortraitP from "../albums/portraits/events/album-events-1-16.JPG"
import PortraitQ from "../albums/portraits/events/album-events-1-17.JPG"
import PortraitR from "../albums/portraits/events/album-events-1-18.JPG"
import PortraitS from "../albums/portraits/events/album-events-1-19.JPG"
import PortraitT from "../albums/portraits/events/album-events-1-20.JPG"



class Family extends Component {

    state = {
      sideDrawerOpen: false
    };
    
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen}
      });
    };
    
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false})
    };  
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler}/>
      }
    return (
      <div>
      <AlbumsHeader drawerClickHandler={this.drawerToggleClickHandler} />
      <div>
         {sideDrawer}
         {backdrop}  
         </div> 
        <div className="portrait-wrap">
        <img src={PortraitB} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitD} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitE} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitF} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitH} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitJ} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitK} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitL} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitQ} alt="alli jo" className="portrait-photo"/>  
        <img src={PortraitP} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitR} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitS} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitA} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitG} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitN} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitT} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitM} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitO} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitC} alt="alli jo" className="portrait-photo"/>
        <img src={PortraitI} alt="alli jo" className="portrait-photo"/>
        </div>
       </div>
    );
  }
  }
  
  export default Family;


