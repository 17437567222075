import React from 'react';
import "./sideDrawer.css";
import { HashLink } from "react-router-hash-link";
import Insta from "../../images/insta.svg"

const sideDrawer = props => (
 
    <nav className="sideDrawer">
        <ul>
        <HashLink to="/"><li><p>Home</p></li></HashLink>
        <HashLink to="/Albums"><li><p>Albums</p></li></HashLink>
        <HashLink to="/About"><li><p>About Me</p></li></HashLink>
        <HashLink to="/Contact"><li><p>Contact Me</p></li></HashLink>
        <a href="https://www.instagram.com/alli.jo.photography/" target="blank__"><img src={Insta} alt="instagram" className="insta-link" /></a>
        </ul>
    </nav>



);

export default sideDrawer;