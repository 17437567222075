import React from 'react'
import LandingPhoto from "./images/allison.JPG";
import './App.css';
import { HashLink } from "react-router-hash-link";
import HeaderLanding from "./Components/header/HeaderLanding";
import "./Components/header/header.css";
import { BackgroundImage } from 'react-image-and-background-image-fade';

function App() {
  return (
  
    <BackgroundImage className="App"
    id="home"
    src={LandingPhoto}
      style={{  
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',

}}>
  

  <HeaderLanding className="header" />
  <div className="link-wrap">
 <HashLink smooth to="About" className="about-link">About Me</HashLink>
 <HashLink smooth to="Albums" className="albums-link">Albums</HashLink>
 <HashLink smooth to="Contact" className="contact-link">Contact</HashLink>
 </div>

    </BackgroundImage>
  
  );
}

export default App;
