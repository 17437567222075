import React, { Component } from "react";
import Header from "../Components/header/Header";
import SideDrawer from "../Components/sideDrawer/sideDrawer";
import Backdrop from "../Components/backdrop/backdrop";
import AboutMe from "../images/aboutme.JPG";
import { Image } from 'react-image-and-background-image-fade'
import "./about.css"
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { SmoothProvider } from 'react-smooth-scrolling';
import slideOne from "../images/slide-image-one.jpg";
import slideTwo from "../images/slide-image-two.JPG";
import slideThree from "../images/slide-image-three.jpg";
import slideFour from "../images/slide-image-four.JPG";
import { HashLink } from "react-router-hash-link";


class About extends Component {

    state = {
      sideDrawerOpen: false
    };
    drawerToggleClickHandler = () => {
      this.setState((prevState) => {
        return {sideDrawerOpen: !prevState.sideDrawerOpen}
      });
    };
    backdropClickHandler = () => {
      this.setState({sideDrawerOpen: false})
    };  
    render() {
      let sideDrawer;
      let backdrop;
      if (this.state.sideDrawerOpen) {
        sideDrawer = <SideDrawer />
        backdrop = <Backdrop click={this.backdropClickHandler}/>
      }
    return (
      <SmoothProvider 
  
      ease={true}
      className="smooth"
      >
      <Header drawerClickHandler={this.drawerToggleClickHandler} id="firstheader"/>
      <div>
         {sideDrawer}
         {backdrop}  
         </div> 
      <div className="Pages"> 
        <div className="about-wrap">
          <div className="about-titles-wrap">
          <h3 className="about-title1" id="aboutTitle">About Me</h3>
          <h2 className="about-title2" id="aboutTitle">Alli Jo Shepherd</h2>
          </div>
          <div className="about-topgroup-wrap">
          <Image src={AboutMe} alt="alli jo" className="about-photo"/>
        <div className="about-text-wrap-1">
        <p>My name is Alli Jo, photography wasn’t something I just decided to jump on but more of a vision I saw and a passion that burned in me. Life brings us heart break, and some times the only greater thing to do is get up and go. 
So for me I did, I stepped into a plane at 19 and moved to Kona Hawaii, in my time there I healed and faced things I didn’t even knew existed. I joined a school for ministry in missions and reaching the unreachable in different countries. 

</p>
        </div>
        </div>
        <div className="about-text-title">
        </div>
        <div className="about-text-wrap-2">
        In all of that, I moved to China for 3 months. And that’s when the burning in my chest and fingers came through. 
Photography, wasn’t just a hobby but very much like therapy for me. 
Creating and capturing the beauty that is simply there but not shown, until it is caught into the lens and seen.
        </div>
        <div className="slide-show-wrap">
        <AwesomeSlider>
    <div><Image src={slideOne} className="slideImages" alt="AlliJo"/></div>
    <div><Image src={slideTwo} className="slideImages" alt="AlliJo"/></div>
    <div><Image src={slideThree} className="slideImages" id="small-slide"alt="AlliJo"/></div>
    <div><Image src={slideFour} className="slideImages" alt="AlliJo"/></div>
    <div><HashLink smooth to="Albums" className="slide-link">View All</HashLink></div>
  </AwesomeSlider>
  </div>
        </div>
       </div>
      </SmoothProvider>
    );
  }
  }
  
  export default About;
